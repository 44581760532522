.app-container {
    text-align: center;
    font-family: Arial, sans-serif;
    color: white;
    position: relative;
    overflow-x: hidden;
}

.video-banner-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.video-slide {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure no overflow */
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Tablet screens */
@media (max-width: 1024px) {
    .video-banner-container {
        height: 70vh; 
    }
    .video-slide {
        height: 70vh; 
    }
}

/* Mobile screens */
@media (max-width: 768px) {
    .video-banner-container {
        height: 60vh;
    }
    .video-slide {
        height: 60vh; 
    }
}

/* Very small screens */
@media (max-width: 480px) {
    .video-banner-container {
        height: 50vh; 
    }
    .video-slide {
        height: 50vh; 
    }
}

/* Card part*/
#right {
    height: 540px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-auto-rows: 280px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    letter-spacing: 2px;
}

#right1, #right2, #right3, #right4 {
    height: 280px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

#right2, #right3 {
    background-color: #051522;
    color: white;
    font-family: 'GillSans';
    font-size: 1.25vw;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%; 
    overflow: hidden;
    cursor: pointer;
}

#right1, #right4 {
    font-family: 'GillSans';
    font-size: 1.25vw;
    display: flex;
    width: 100%; 
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
    color: black;
    cursor: pointer;
}


#right i {
    font-size: 1.15em;
}

#right i:hover {
    color: red;
}

#right p {
    display: grid;
    place-content: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1440px) {
    #left {
        width: 790px;
    }

    #right {
        grid-template-columns: 500px 500px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    #right1, #right2, #right3, #right4 {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    #right p {
        font-size: 1.25vw;
    }
}

@media (max-width: 1024px) {
    #right {
        grid-template-columns: 1fr;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    #right1, #right2, #right3, #right4 {
        width: 100%; 
        font-size: 16px; 
    }

    #right p {
        font-size: 1.15vw; 
    }
}


@media (max-width: 768px) {
    #main{
        height:1200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
   
    #right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height:1800px;
    }
    #right p{
        font-family: 'GillSansMedium';
        font-size: 2.25vw;
    }
    #right i {
        font-size: 2.25em;
    }
    #right div {
        width: 100%;
        height: 300px; 
        border: 2px solid grey;
        cursor: pointer;
    }
}

@media (max-width: 480px) {
    
    #right {
        grid-template-columns: 1fr; 
    }

    #right div {
        padding: 5px; 
    }

    #right i {
        font-size: 3.25em;
    }

    #right p{
        font-family: 'GillSansMedium';
        font-size: 3.25vw;
    }
} 
/* image-slide */
.slider-section {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  .slider-section img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 94vw;
    overflow: hidden;
  }
   
  /* Responsive h3 font size for smaller screens */
  #car-image1 h3,
  #car-image2 h3,
  #car-image3 h3,
  #car-image4 h3 {
    font-size: 50px;
  }
  
  .full {
    display: flex;
    align-items: center;
    gap: 55px;
  }
  
  .btn {
    height: 120px;
    display: flex;
    align-items: center;
    gap: 25px;
  }
  
  .btn button {
    padding: 18px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .btn button:hover{
    background-color: rgb(242, 114, 114);
  }
  
  /* Responsive design for small screens */
  @media (max-width: 768px) {
    #car-image1,
    #car-image2,
    #car-image3,
    #car-image4 {
      height: 80vh;
    }
  
    #car-image1 h3,
    #car-image2 h3,
    #car-image3 h3,
    #car-image4 h3 {
      font-size: 30px;
    }
  
    .full {
      gap: 25px;
    }
  
    .btn {
      height: 80px;
      gap: 15px;
    }
  
    .btn button {
      padding: 10px 15px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
   
  
    .full {
      gap: 15px;
    }
  
    .btn {
      height: 60px;
      gap: 10px;
    }
  
    .btn button {
      padding: 8px 12px;
      font-size: 10px;
    }
  }
  
/* Content part */
#content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    background-color: black;
    color: white;
}

.con {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    text-align: center;
   
}

.con p { 
    max-width: 100%;
    line-height: 1.5;
}

.con img {
    width: 100%;
    max-width: 260px;
    height: 100px;
    border-radius: 10px;
}

/* Tablet*/
@media (min-width: 768px) and (max-width: 1024px) {
    .con {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        gap: 10px;
        margin-left: 5px;
    }

    .con p {
        font-size: 9pt;
        max-width: 600px;
        line-height: 1.6;
    }

    .con b {
        font-family: 'GillSansSemiBold';
        font-size: 1.02725vw;
        font-weight: 700;
    }

    .con img {
        max-width: 50%; 
        height: auto;
    }
}

@media (min-width: 1024px) {
    .con {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        padding: 0;
        margin-left: 5px;
    }

    .con p {
        max-width: 800px;
        line-height: 1.8;
    }

    .con b {
        font-family: 'GillSansSemiBold';
        font-weight: 700;
    }

    .con img {
        max-width: 500px;
        height: 272px;
    }
}

/* Mobile screens */
@media (max-width: 768px) {
    .con {
        flex-direction: column;
        text-align: left;
        margin: 0px 5px;
        gap: 28px;
        margin-left: 5px;
        padding: 0;
    }

    .con p {
        line-height: 1.4;
    }


    .con img {
        width: 100%;
        max-width: 360px;
        height: auto;
    }
}



/* Footer upper part*/
#calc {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
}

.clc {
    margin: 0;
    padding: 0;
    height: 70px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 4px;
    border: 1px solid whitesmoke;
    cursor: pointer;
    text-align: center;
    width: calc(33.33% - 20px);
    gap: 7px;
    box-sizing: border-box;
    position: relative;
}

.clc i {
    font-size: 35px;
}

.clc i:hover {
    color: red;
}

/* Responsive for smaller screens */
@media (max-width: 768px) {
    #calc {
        flex-direction: column;
        height: auto;
    }

    .clc {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        font-size: 14px;
        border: none;
    }

    .clc i {
        font-size: 30px;
    }

    .clc:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%; 
        height: 2px;
        background-color: whitesmoke;
    }
}

/* Hide the divider for larger screens */
@media (min-width: 769px) {
    .clc::after {
        display: none;
    }
}
