/* Slide-in animation for image */
@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Image container for animated image with effects */
  .imageContainer img {
    animation: slideIn 1.5s ease-out;
    width: 80%;
    border-radius: 10px; /* Rounded corners for a softer look */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5); /* Add a shadow for depth */
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  
  /* On hover, slightly enlarge the image */
  .imageContainer img:hover {
    transform: scale(1.05);
  }
  
  /* Gradient overlay to enhance the image appearance */
  .imageOverlay {
    position: relative;
  }
  
  .imageOverlay:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)); /* Gradient from top to bottom */
    border-radius: 10px;
    z-index: 1;
  }
  
  /* Adding filter to enhance the contrast */
  .imageContainer img {
    filter: brightness(0.9) contrast(1.2);
  }
  .logoImage {
    max-width: 200px; /* Adjust the size as needed */
    height: auto;
    margin-bottom: 20px; /* Space below the logo */
    display: block;
    margin-left: auto;
    margin-right: auto; /* Centering the logo */
  }
  
  /* Image Container Styles (optional) */
  .imageContainer {
    position: relative;
  }
  
  /* Responsive Image Styles */
  .responsiveImage {
    width: 100%;
    height: auto;
  }
  