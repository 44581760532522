/* //------------------------second rule css--------------------------- */
/* .geely-car-360-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f5f5f5;
    height: 80vh; /* Adjust as needed */
    /* width: 80vw; /* Adjust as needed */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* overflow: hidden; */
    /* cursor: grab; */
    /* position: relative; */
/* }  */ 
/* 
.car-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */ 

/* //-------------------------Third Rule css ----------------------- */
.geely-car-360-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevent scrollbars */
    background-color: #f5f5f5; /* Background color */
    position: relative;
}



