* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  #container {
    overflow: hidden;
  }
  /* Banner Section */
#banner-image-hecblack {
  height: 110vh; 
  margin-top: 40px;
  width: 100%;
  background-image: url("../../../../images/hecblack/hecblack.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-start; /* Align to left on larger screens */
  align-items: center;
  padding-left: 50px; /* Add some padding on left */
}

/* Left Section Content */
#hecblack-left {
  height: auto;
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 13px;
  margin-top: 50px;
}

/* Buttons layout */
.hecblack {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Button styling */
.hecblack button {
  padding: 5px;
  width: 150px;
  border: none;
  font-size: 12px;
  outline: none;
  color: white;
  background-color: #E62333;
}

@media (max-width: 1023px) {
  #banner-image-hecblack {
    justify-content: center; /* Center content on smaller screens */
    align-items: center;
    padding-left: 0;
    height: 60vh; 
    margin-top: 40px;
    background-size: 100% 100%; 
  }

  #hecblack-left {
    width: 100%;
    margin-top: 30px; 
  }

  .hecblack {
    flex-direction: row; 
    gap: 10px; 
  }

  .hecblack button {
    width:250px;
    font-size: 14px; 
  }
}

@media (max-width: 480px) {
  #banner-image-hecblack {
    padding: 10px;
    margin-top: 40px;

  }

  #hecblack-left {
    margin-top: 20px;
  }
  .hecblack {
    flex-direction: row; 
    gap: 10px; 
  }

  .hecblack button {
    width:150px;
    font-size: 10px;

  }
}
/* Default styles for larger screens (laptops/desktops) */
#hecpart2 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/hecblack/hecblack2.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}

/* Responsive for tablets and smaller screens */
@media (max-width: 1024px) {
  #hecpart2,#hecpart3,#hecpart4,#hecpart5 {
    height: 70vh; 
    background-size: 100% 100%; 
    background-position: center; 
    padding-left: 20px;
    padding-right: 20px; 
  }
}

/* Responsive for mobile screens */
@media (max-width: 780px) {
  #hecpart2,#hecpart3,#hecpart4,#hecpart5 {
    background-size: 100% 100%; 
    height: 70vh; 
    justify-content: center; 
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Extra small screens (max-width: 480px) */
@media (max-width: 480px) {
  #hecpart2,#hecpart3,#hecpart4,#hecpart5 {
    height: 50vh; 
    background-size: 100% 100%; 
    background-position: center; 
  }
}
#hecpart3 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/hecblack/hecblack3.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}
#hecpart4 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/hecblack/hecblack4.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}
#hecpart5 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/hecblack/hecblack5.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}
   
    