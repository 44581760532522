#price {
    padding: 2px;
  }
  
  .city-link {
    display: block;
    text-decoration: none;
    color:#2379c5;
    cursor: pointer;
    padding: 5px; 
    margin: 0; 
    border-radius: 5px;
    text-align: center;
    font-size: 16px; 
    font-weight: 700;
    font-family: 'GillSansSemiBold';
}
 
  .city-link:hover {
    color: #2379C9; 
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .city-link {
      font-size: 4vw; 
      padding: 3px; 
      text-align: left;
      margin-left: 20px;
    }
  }