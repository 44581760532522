#banner-image-contact {
    height: 100vh; 
    width: 100%;
    background-image: url("../../images/contactcover.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center; 
    align-items: center;
    position: relative;
    z-index: 1;
  }
  #write{
    z-index: 5;
  }
  
  @media (max-width: 1023px) {
    #banner-image-contact {
      justify-content: center; /* Center content on smaller screens */
      align-items: center;
      padding-left: 0;
      height: 60vh; 
      background-size: 100% 100%; 
    }
}
  
  @media (max-width: 480px) {
    #banner-image-contact {
      padding: 10px;
    }
  }