* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  #container {
    overflow: hidden;
  }
  
  #banner-image-glostorseries {
    height: 120vh; 
    width: 100%;
    background-image: url("../../../../images/bannerimage/gs-bn-dsc-026.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensure image covers the banner */
    display: grid;
    grid-template-columns: 280px 570px 280px;
    justify-content: space-between;
    align-items: center;
    margin-top: 90px;
  }
  
  /* Left and Right Sections */
  #hect-left, #hect-right {
    height: 100%;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center the content vertically */
  }
  
  #hl1, #hl2, #hr1, #hr2 {
    margin-top: 50px; /* Reduce the gap */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
    font-size: 1.2vw;
    font-weight: 600;
    font-family: 'GillSansSemiBold';
    line-height: 1em;
  }
  
  /* Center Section */
  #hect-center {
    height: 100%;
    width: 570px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    margin: 0 auto;
  }
  #hl1 img,#hl2 img,#hr1 img,#hr2 img{
    height:80px;
  }
  
  /* Responsive for larger laptop screens */
  @media (min-width: 1024px) {
    #banner-image-glostorseries {
      grid-template-columns: 280px 570px 280px;
      margin-top: 90px;
      padding-bottom: 50px;
    }
  
    #hect-left, #hect-right {
      margin: 0;
    }
    #hect-right{
      margin-left: -100px;
    }
    #hect-center {
      margin: 0 auto;
    }
  }

  @media (min-width: 1224px) {
    #banner-image-glostorseries {
      grid-template-columns: 280px 570px 280px;
      margin-top: 20px;
      margin-top: 100px;
      padding-bottom: 50px;
    }
  
    #hect-left, #hect-right {
      margin: 0;
    }
    #hect-right{
      margin-left: -100px;
    }
    #hect-center {
      margin: 0 auto;
    }
  }
  @media (min-width: 1440px) {
    #banner-image-glostorseries {
      grid-template-columns: 280px 570px 280px;
      margin-top: 100px;
      padding-bottom: 50px;
    }
  
    #hect-left, #hect-right {
      margin: 0;
    }
    #hect-right{
      margin-left: -100px;
    }
    #hect-center {
      margin: 0 auto;
    }
  }
  
  /* Responsive for tablets and mobile screens */
  @media (max-width: 780px) {
    #banner-image-glostorseries {
      grid-template-columns: 1fr;
      grid-auto-rows: 1140px 60px 200px;
      justify-items: center;
      height: 230vh;
      background-size: cover; 
      padding-bottom: 50px;
      background-image: url("../../../../images/bannerimage/gs-bn-dsc-027-portrait.webp");
    }
    #gs2{
      margin-bottom:-350px ;
    }
  
    #hect-center {
      order: 1;
      width: 100%;
      gap: 20px;
    }
  
    #hect-left, #hect-right {
      order: 2;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      margin-top: 20px;
    }
  
    #hl1, #hl2, #hr1, #hr2 {
      font-size: 14px;
      margin-top: 50px;
      font-weight: 700;
    }
  }
  
  /* Responsive for very small mobile screens */
  @media (max-width: 480px) {
    #banner-image-glostorseries {
      /* height: 100%;  */
      background-size: 100% 100%;
      grid-auto-rows: 730px 180px 100px;
      padding-bottom: 50px;
    }
  
    #hect-center {
      order: 1;
      width: 100%;
      gap: 0px;
      margin-top: 80px;
    }
  
    #hect-left, #hect-right {
      flex-direction: row;
      order: 2;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
      /* margin: 20px; */
      padding: 20px;
    }
  
    #hl1, #hl2, #hr1, #hr2 {
      font-size: 10px;
      font-weight: 600;
      margin-top: 40px;
      line-height:13px ;
      text-align: center;
    }
    
    #hl1 img,#hl2 img,#hr1 img,#hr2 img{
      height:80px;
      width:80px;
    }
    #hl2,#hr2{
      margin-top: 25px;
    }
  }
  
  
  /* Image */
  #stormimg{
    height: 100vh; 
    width: 100%;
    background-image: url("../../../../images/storm/storm.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    padding-left: 50px; 
  }
  @media (max-width: 1024px) {
    #stormimg {
      height: 70vh; 
      background-size: 100% 100%; 
      background-position: center; 
      padding-left: 20px;
      padding-right: 20px; 
    }
  }
  
  /* Responsive for mobile screens */
  @media (max-width: 780px) {
    #stormimg {
      background-size: 100% 100%; 
      height: 70vh; 
      justify-content: center; 
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  /* Extra small screens (max-width: 480px) */
  @media (max-width: 480px) {
    #stormimg {
      height: 50vh; 
      background-size: 100% 100%; 
      background-position: center; 
    }
  }