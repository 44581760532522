* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  #container {
    overflow: hidden;
  }
  /* Banner Section */
#banner-image-glosterblack {
  height: 110vh; 
  width: 100%;
  background-image: url("../../../../images/blackstorm/black.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-start; /* Align to left on larger screens */
  align-items: center;
  padding-left: 50px; /* Add some padding on left */
}

/* Left Section Content */
#glost-left {
  height: auto;
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 13px;
  margin-top: 50px;
}

/* Buttons layout */
.glost {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Button styling */
.glost button {
  padding: 5px;
  width: 150px;
  border: none;
  font-size: 12px;
  outline: none;
  color: white;
  background-color: #E62333;
}

@media (max-width: 1023px) {
  #banner-image-glosterblack {
    justify-content: center; /* Center content on smaller screens */
    align-items: center;
    padding-left: 0;
    height: 60vh; 
    background-size: 100% 100%; 
  }

  #glost-left {
    width: 100%;
    margin-top: 30px; 
  }

  .glost {
    flex-direction: row; 
    gap: 10px; 
  }

  .glost button {
    width:250px;
    font-size: 14px; 
  }
}

@media (max-width: 480px) {
  #banner-image-glosterblack {
    padding: 10px;
  }

  #glost-left {
    margin-top: 20px;
  }
  .glost {
    flex-direction: row; 
    gap: 10px; 
  }

  .glost button {
    width:150px;
    font-size: 10px;

  }
}
/* Default styles for larger screens (laptops/desktops) */
#part2 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/blackstorm/black2.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}

/* Responsive for tablets and smaller screens */
@media (max-width: 1024px) {
  #part2,#part3,#part4,#part5 {
    height: 70vh; 
    background-size: 100% 100%; 
    background-position: center; 
    padding-left: 20px;
    padding-right: 20px; 
  }
}

/* Responsive for mobile screens */
@media (max-width: 780px) {
  #part2,#part3,#part4,#part5 {
    background-size: 100% 100%; 
    height: 70vh; 
    justify-content: center; 
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Extra small screens (max-width: 480px) */
@media (max-width: 480px) {
  #part2,#part3,#part4,#part5 {
    height: 50vh; 
    background-size: 100% 100%; 
    background-position: center; 
  }
}
#part3 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/blackstorm/black3.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}
#part4 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/blackstorm/black4.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}
#part5 {
  height: 100vh; 
  width: 100%;
  background-image: url("../../../../images/blackstorm/black5.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding-left: 50px; 
}
   
    